import React from "react";
import Link from "components/Common/Link";
import Icon from "components/Common/Icon";
import Card from "components/Cards/Card";
import CardIcon from "components/Cards/CardIcon";
import CardImage from "components/Cards/CardImage";
import { faker } from "@faker-js/faker";

export default function About() {
  faker.locale = "ja";
  return (
    <main>
      <div className="relative pt-12 pb-32 flex content-center items-center justify-center min-h-screen-75">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1517807289433-f0282e362596?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1307&q=80')",
          }}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-50 bg-background"
          ></span>
        </div>
        <div className="container relative mx-auto">
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div className="pr-12">
                <h1 className="text-foreground font-semibold text-5xl">
                  {faker.lorem.words(3)}
                </h1>
                <p className="mt-4 text-lg text-foreground-200">
                  {faker.lorem.paragraph()}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-foreground-700 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section
        id="about1"
        className="pb-20 -mt-40 bg-background-300 text-foreground-300"
      >
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap">
            <div className="md:w-4/12 lg:pt-16 p-4">
              <CardIcon
                title={faker.lorem.words(3)}
                summary={faker.lorem.paragraph()}
                icon="award"
                iconClassName="bg-primary-300 text-foreground-300"
                className="bg-background-800 text-foreground-800"
              />
            </div>
            <div className="md:w-4/12 p-4">
              <CardIcon
                title={faker.lorem.words(3)}
                summary={faker.lorem.paragraph()}
                icon="retweet"
                iconClassName="bg-primary-300 text-foreground-300"
                className="bg-background-800 text-foreground-800"
              />
            </div>
            <div className="md:w-4/12 lg:pt-10 p-4">
              <CardIcon
                title={faker.lorem.words(3)}
                summary={faker.lorem.paragraph()}
                icon="fingerprint"
                iconClassName="bg-primary-300 text-foreground-300"
                className="bg-background-800 text-foreground-800"
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center mt-16">
            <div className="w-full md:w-7/12 lg:w-5/12 px-4 pb-12 md:pb-0 mr-auto ml-auto">
              <h3 className="text-primary-500 text-3xl mb-2 font-semibold leading-normal">
                <Icon
                  icon="user-friends"
                  cover={true}
                  diameter={12}
                  className="text-xl mr-2 bg-primary-300 text-foreground"
                />
                {faker.lorem.words(3)}
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                {faker.lorem.paragraph()}
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-4">
                {faker.lorem.paragraph()}
              </p>
              <Link href="/" className="font-bold capitalize mt-8">
                {faker.lorem.words(4)}{" "}
                <Icon className="text-xs" icon="angle-double-right" />
              </Link>
            </div>

            <div className="mr-auto ml-auto md:w-5/12 lg:w-5/12">
              <CardImage
                title={faker.lorem.sentence()}
                content={faker.lorem.sentences(2)}
                image="https://images.unsplash.com/photo-1492648272180-61e45a8d98a7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
                imageTitle="services"
                polygon={true}
                polygonColor="foreground-200"
                className="bg-background-800 text-foreground-800"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="about2" className="relative py-20">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-foreground-900 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 lg:w-4/12 ml-auto mr-auto px-4 md:px-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1530541835461-dedaf9cf368a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
              />
            </div>
            <div className="w-full md:w-7/12 lg:w-6/12 mx-auto px-4">
              <div className="pt-12 md:pt-0 lg:pr-12">
                <h3 className="text-primary-500 text-3xl font-semibold">
                  <Icon
                    icon="rocket"
                    cover={true}
                    diameter={12}
                    className="text-xl mr-2 bg-primary-300 text-foreground"
                  />
                  {faker.lorem.words(3)}
                </h3>
                <p className="mt-4 text-lg leading-relaxed">
                  {faker.lorem.paragraphs(2)}
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <Icon
                        icon="fingerprint"
                        cover={true}
                        className="bg-background-800 text-foreground-700 mr-3"
                      />
                      <h4 className="">{faker.lorem.words(4)}</h4>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <Icon
                        icon="code"
                        cover={true}
                        className="bg-background-800 text-foreground-700 mr-3"
                      />
                      <h4 className="">{faker.lorem.words(3)}</h4>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <Icon
                        icon="paper-plane"
                        cover={true}
                        className="bg-background-800 text-foreground-700 mr-3"
                      />
                      <h4 className="">{faker.lorem.words(3)}</h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about3" className="pb-20 relative block bg-background-300">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-foreground-700 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4 pt-12 lg:pt-24">
          <div className="flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-3xl font-semibold text-primary-400">
                <Icon
                  icon="robot"
                  cover={true}
                  diameter={12}
                  className="text-xl mr-2 bg-primary-300 text-foreground"
                />
                {faker.lorem.words(3)}
              </h2>
              <p className="text-lg leading-relaxed mt-4 mb-4">
                {faker.lorem.paragraph()}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap mt-12 justify-center">
            <div className="w-full lg:w-3/12 px-4 lg:px-6 text-center">
              <Icon
                icon="medal"
                cover={true}
                diameter={12}
                className="text-xl bg-primary-500"
              />
              <h5 className="capitalize text-xl mt-5 font-semibold">
                {faker.lorem.words(4)}
              </h5>
              <p className="mt-2 mb-4">{faker.lorem.words(20)}</p>
            </div>
            <div className="w-full lg:w-3/12 px-4 lg:px-6 text-center">
              <Icon
                icon="poll"
                cover={true}
                diameter={12}
                className="text-xl bg-primary-500"
              />
              <h5 className="capitalize text-xl mt-5 font-semibold">
                {faker.lorem.words(5)}
              </h5>
              <p className="mt-2 mb-4">{faker.lorem.words(20)}</p>
            </div>
            <div className="w-full lg:w-3/12 px-4 lg:px-6 text-center">
              <Icon
                icon="lightbulb"
                cover={true}
                diameter={12}
                className="text-xl bg-primary-500"
              />
              <h5 className="capitalize text-xl mt-5 font-semibold">
                {faker.lorem.words(5)}
              </h5>
              <p className="mt-2 mb-4">{faker.lorem.words(20)}</p>
            </div>
          </div>
        </div>
      </section>

      <section id="about4" className="relative block pb-24 bg-background-100">
        <div className="bg-background-300 w-full h-32"></div>
        <div className="container mx-auto px-4 -mt-32">
          <div className="flex flex-wrap justify-center ">
            <div className="w-full lg:w-6/12 px-4">
              <Card
                title="Contact"
                className="bg-background-200 text-foreground-200"
              >
                <form name="contact" data-netlify="true" method="POST">
                  <div className="">
                    <div className="w-full my-1 ">
                      <div className="mb-2">
                        <label className="block uppercase" htmlFor="email">
                          Email
                        </label>
                      </div>
                      <input
                        name="email"
                        type="email"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Your email"
                        required={true}
                        minLength={5}
                        aria-invalid="false"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="mb-2">
                        <label className="block uppercase" htmlFor="name">
                          Full name
                        </label>
                      </div>
                      <input
                        name="name"
                        type="string"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Your name"
                        required={true}
                        minLength={5}
                        aria-invalid="false"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="mb-2"></div>
                      <input
                        name="form-name"
                        type="hidden"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder=""
                        aria-invalid="false"
                        value="contact"
                      />
                    </div>
                    <div className="w-full my-1 ">
                      <div className="flex mb-2">
                        <label className="block uppercase" htmlFor="message">
                          Message
                        </label>
                      </div>
                      <textarea
                        name="message"
                        className="px-3 py-3 form-input placeholder-gray-400 bg-gray-100 focus:bg-yellow-100 text-gray-800 rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Your message"
                        required={true}
                        rows={5}
                        aria-invalid="false"
                      ></textarea>
                    </div>
                  </div>
                  <div className="w-full text-center mt-6 flex text-sm">
                    <div className="w-full">
                      <div className="w-full text-center">
                        <button
                          className="items-center text-gray-200 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 bg-primary-300 w-full "
                          type="submit"
                        >
                          Contact
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
