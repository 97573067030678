import React from "react";
import Layout from "layouts/Layout";
import AboutContents from "contents/About";

export default function Landing() {
  return (
    <Layout title="About">
      <AboutContents />
    </Layout>
  );
}
